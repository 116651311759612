<template>
  <BaseListPage locale-section="pages.signa1" route="signum1">
    <Signa1List />
  </BaseListPage>
</template>

<script>
export default {
  name: "Signa1Page",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    Signa1List: () => import("./Signa1List")
  }
};
</script>
